<template>
  <PageLayout :sidebar-menu-list="sidebarMenuList">
    <router-view />
  </PageLayout>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { MenuItemType, PageLayout } from "@tager/admin-layout";
import { useI18n } from "@tager/admin-services";
import { HomeIcon, SettingsIcon, ViewListIcon } from "@tager/admin-ui";

import { getLinks } from "@/constants/links";

export default defineComponent({
  name: "App",
  components: { PageLayout },
  setup() {
    const links = computed(() => getLinks());

    const sidebarMenuList: Array<MenuItemType> = [
      { id: "home", icon: HomeIcon, ...links.value.HOME },
      { id: "pages", icon: ViewListIcon, ...links.value.PAGE_LIST },
      {
        id: "blog",
        icon: ViewListIcon,
        text: "Блог",
        children: [
          links.value.BLOG_POST_NEWS_LIST,
          links.value.BLOG_POST_OFFERS_LIST,
          links.value.BLOG_POST_REVIEWS_LIST,
          links.value.BLOG_POST_LESSONS_LIST,
          links.value.BLOG_CATEGORY_LIST,
        ],
      },
      {
        id: "banners",
        icon: ViewListIcon,
        text: "Баннеры",
        children: [links.value.BANNERS_LIST, links.value.BANNERS_ZONES_LIST],
      },
      { id: "videos", icon: ViewListIcon, ...links.value.VIDEOS_LIST },
      { id: "files", icon: ViewListIcon, ...links.value.FILES_LIST },
      {
        id: "commands",
        icon: SettingsIcon,
        text: "Команды",
        children: [
          links.value.CRON_COMMANDS,
          links.value.CRON_COMMANDS_LOGS,
          links.value.CRON_LOGS,
        ],
      },
      {
        id: "admins",
        text: "Администраторы",
        icon: SettingsIcon,
        children: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
      },
    ];

    return {
      sidebarMenuList,
    };
  },
});
</script>

<style scoped lang="scss"></style>
